.footer{

    margin-top: 100px;

    &--block{
        background: #000D1A;
        padding-top: 88px;
        padding-bottom: 30px;
    }


    &--container{
        display: flex;
        flex-direction: column;
    }


    &--form{
        position: relative;

        .form--block{

            width: 635px;

            display: flex;
            flex-direction: column;
            margin-left: 145px;

            .block{

                &--title{
                    font-weight: 700;
                    font-size: 62px;
                    line-height: 80px;
                    color: #FFFFFF;
                    margin-bottom: 22px;

                    @media(max-width: 1920px){
                        font-size: calcFluidAll(20, 62);
                        line-height: calcFluidAll(20, 60);
                    }

                }

                &--description{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    color: #FFFFFF;
                    margin-bottom: 54px;

                    @media(max-width: 1920px){
                        font-size: calcFluidAll(16, 24);
                    }
                }

                &--inputs{
                    display: flex;
                    gap: 30px;
                    margin-bottom: 40px;

                    .input--element{
                        width: 303px;
                    }

                    .input--phone{
                        border: 1px solid #FFFFFF;
                        border-radius: 55px;
                        background: none;
                        padding-left: 34px;
                        padding-right: 34px;

                        &, &::placeholder{
                            font-weight: 400;
                            font-size: 21px;
                            line-height: 25px;
                            color: #FFFFFF;
                        }

                    }

                }
                
                &--agree{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 120%;
                    color: #FFFFFF;

                    .agree--link{
                        color: #72B3FF;
                        text-decoration-line: underline;

                        &:hover{
                            text-decoration-line: none;
                        }

                    }

                }

            }
        }


        .form--background{
            position: absolute;
            left: 0;
            right: 30px;
            top: -280px;
            bottom: 0;
            pointer-events: none;
            .background--image{
                position: absolute;
                right: -100px;
                bottom: -136px;
                pointer-events: none;
            }

            @media(max-width: 1919px)
            {
                overflow: hidden;
            }
        }

        
        @media(max-width: 1670px){
            .form--block{
                margin-left: 50px;
            }

            .form--background{
                .background--image{
                    object-fit: contain;
                    right: -160px;
                }
            }
        }

        @media(max-width: 1500px){
            .form--block{
                width: 400px;
                margin-left: 25px;
                .block--inputs{
                    flex-direction: column;

                    .input--element{
                        width: 100%;
                        height: 50px;
                    }

                    .input--submit{
                        padding: 10px;
                    }

                }
            }
        }

        @media(max-width: 1269px){

            .form--block{
                width: 100%;
                margin-left: 0;
                align-items: center;

                .block--title{
                    font-weight: 400;
                    line-height: 120%;
                    text-align: center;
                }

                .block--description{
                    font-weight: 400;
                    line-height: 120%;
                    text-align: center;
                }

                .block--agree{
                    text-align: center;
                }

            }

            .form--background{
                left: -100px;
                right: 10px;
                top: -206px;
                bottom: 0;

                .background--image{
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 260px;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    &--content{
        border: solid 1px #FFFFFF;
        border-left: 0;
        border-right: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 116px;
        margin-bottom: 25px;
        display: flex;

        .content--logo{

            width: 165px;
            height: 65px;
            margin-right: 80px;
            @media(max-width: 1920px){
                margin-right: calcFluid(60, 80, 1000, 1920);
            }

            .logo--image{
                width: 100%;
                max-width: 200px;
                height: 100%;
                object-fit: contain;
            }

        }

        .content--links{
            flex-grow: 1;

            .links--table{
                display: flex;
                gap: 10px;
                justify-content: space-between;

                .table--column{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .table--row{
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 125%;
                        color: #FFFFFF;

                        &:hover{
                            text-decoration: underline;
                        }

                    }

                    @media(max-width: 1979px){
                        gap: calcFluidAll(10, 20);
                        .table--row{
                            font-size: calcFluidAll(16, 21);
                        }
                    }
                }

            }
        }

        .content--information{
            margin-left: 112px;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            color: rgba(255, 255, 255, 0.75);

            .information--list{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 24px;

                .list--item{

                    * {
                        color: rgba(255, 255, 255, .75);
                    }

                    &.item--email{
                        text-decoration: underline;
                    }
                }
            }

        }

        @media (max-width: 1550px) {

            .content--links{
                .links--table{
                    gap: 40px;
                }
            }

            .content--information{
                margin-left: 30px;
            }
        }

        @media (max-width: 1279px) {

            margin-top: 60px;

            .content--links{
                .links--table{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;

                    .table--column{
                        gap: 20px;
                        .table--row{
                            height: 38px;
                        }
                    }
                }
            }
        }

        @media (max-width: 1050px) {
            flex-direction: column;

            .content--logo{
                margin-bottom: 40px;
                margin-right: 0;
                display: flex;
                width: 100%;
            }

            .content--links{
                display: none;
            }

            .content--information{
                margin-left: 0;

                .information--list{
                    gap: 20px;

                    .list--item{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }

                }

            }

        }

    }


    &--bottom{
        display: flex;
        justify-content: space-between;

        .bottom--element{
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #72B3FF;

            *{
                color: #72B3FF !important;
            }

            &.element--placestart{
                display: flex;
                align-items: center;
                gap: 9px;
                text-decoration: none;

                .placestart--icon{
                    width: 144px;
                    height: 28px;
                    fill: #72B3FF;
                }

            }

        }

        @media (max-width: 1050px) {
            flex-direction: column;
            gap: 14px;
            .bottom--element{
                color: #72B3FF;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
            }
        }

    }
}