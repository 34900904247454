.search-line{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid $main-clr;
    width: 100%;
    height: 45px;
    position: relative;
    &.error{
        border-color: #ff2828;
    }
    input{
        flex: 1 0 auto;
        border: none;
        height: 100%;
        padding: 0 15px;
    }
    button{
        border: none;
        width: 45px;
        height: 45px;
        position: relative;
        right: -1px;
        svg{
			width: 20px;
			height: 20px;
			stroke: $alt-clr;
            fill: transparent;
		}
    }
    &__result{
        display: none;
        width: 100%;
        position: absolute;
        top: calc(100% + 1px);
        background-color: #fff;
        padding: 30px 20px;
        &.opened{
            display: block;
        }
        &:empty{
            display: none !important;
        }
    }
    &__list{

    }
    &__title{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #282828;
        margin: 0 0 10px;
    }
    &__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        span{
            font-size: 15px;
            line-height: 18px;
            color: #282828;
            transition: color $tr-time;
        }
        svg{
            height: 12px;
            width: 8px;
            fill: #999999;
        }
        &:hover{
            span{
                color: $main-clr-hover;
            }
        }
    }
    &__extra{
        border-top: 1px solid #D0D0D0;
        padding: 15px 0 0;
        margin: 30px 0 0;
    }
}