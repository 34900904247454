@media(max-width: 1269px){

    header{
        height: 50px;
    }

    .header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 10;
    }
}

.header--block.block--mobile{

    display: none;

    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);

    @media(max-width: 1270px){
        display: block;
    }

    .header--top{
        padding: 0;
        height: 100%;
    }

    .top--logo{
        padding-top: 9px;
        padding-bottom: 9px;
        max-width: 100px;
        display: flex;
        align-items: center;

        img{
            width: 100%;            
        }
    }

    .top--container{
        justify-content: space-between;
        padding-left: 21px;
        align-items: initial;
    }

    .mobile--container{
        display: flex;
    }

    .mobile--element{
        height: 100%;
        width: 50px;
        position: relative;
    }

    .mobile--cart{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
            position: absolute;
            width: 18px;
            height: 18px;
            fill: #0056A5;
            stroke: #0056a5;
        }

        .cart--value{
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            width: 14px;
            height: 14px;
            text-align: center;
            background: black;
            color: white;
            border-radius: 100%;
            font-size: 10px;
            top: 13px;
            right: 10px;
            font-family: 'PT Sans Narrow';
        }

    }

    .mobile--call{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
            position: absolute;
            width: 18px;
            height: 18px;
            fill: #0056A5;
            pointer-events: none;
        }
    }

    .mobile--open-list{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .svg-default{
            position: absolute;
            width: 26px;
            height: 16px;
            stroke: #0056A5;
        }

        .svg-open{
            display: none;
            position: absolute;
            width: 26px;
            height: 16px;
            stroke: #A9A9A9;
        }

        &.open{
            .svg-default{
                display: none;
            }
    
            .svg-open{
                display: block;
            }
        }

    }
}

.header--block.block--sidebar{

    display: none;
    padding-top: 20px;
    background: white;

    &.open{
        display: block;
    }

    .block--search-container{
        .block--search{
            border-color: #A9A9A9;
            margin-bottom: 20px;
            margin-left: 21px;
            margin-right: 21px;
            position: relative;
    
            .search--input{
                &, &::placeholder{
                    font-weight: 400;
                    font-size: 12px;
                    font-family: 'PT Sans Narrow';
                    line-height: 125%;
                    color: #636363;
                }
            }
    
            .search--submit{
                svg{
                    stroke: #636363;
                }
            }
        }
    
        .search--result{
    
            margin-left: 21px;
            margin-right: 21px;
            margin-bottom: 25px;
            padding-left: 20px;
    
            .search-line__item{
                &:hover, &:hover span{
                    color: #0056a5;
                }
            }
    
            display: none;
            &.opened{
                display: block;
            }

            &.empty{
                display: none !important;
            }
        }
    }

    .sidebar--container{
        .sidebar--dynamic{
            position: relative;

            $paddings: 0px 21px 30px 21px;

            .dynamic--container{
                display: flex;
                flex-direction: column;
                padding: $paddings;
            }

            .dynamic--item{

                &.item--city-selected{
                    margin-bottom: 20px;
                }

                &.item--categories-list{
                    margin-bottom: 30px;
                }

                &.item--contacts{
                    .contact--item{

                        &.item--address{
                            margin-bottom: 5px;
                        }

                        &.item--worktime{
                            margin-bottom: 10px;
                        }

                        &.item--call{
                            display: flex;
                            flex-direction: column;
                            gap: 24px;

                            .call--phone{
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                .phone--icon{
                                    position: relative;
                                    width: 18px;
                                    height: 18px;
                                    svg{
                                        top: 0;
                                        left: 0;
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        fill: #0056A5;
                                    }
                                }
                                .phone--value{
                                    font-weight: 500;
                                    font-size: 24px;
                                    line-height: 125%;
                                    color: #1E1E1E;  
                                    font-family: 'PT Sans Narrow';                                  
                                }
                            }

                        }
                    }
                }
            }

            .dynamic--move_container{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                overflow: hidden;

                .move--item{

                    padding: $paddings;
                    position: absolute;
                    left: 100%;
                    right: -100%;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: all;
                    transition: all .2s linear;
                    background: white;

                    &.active{
                        left: 0;
                        right: 0;
                    }

                    .item--container{
                        display: flex;
                        flex-direction: column;
                    }

                    .item--prev{
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        margin-bottom: 20px;
                        cursor: pointer;

                        .prev--arrow{
                            position: relative;
                            width: 12px;
                            height: 10px;
                            svg{
                                position: absolute;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                stroke: #272727;
                            }
                        }
                        .prev--label{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 125%;
                            color: #272727;    
                            font-family: 'PT Sans Narrow';                        
                        }
                    }
                }

            }
        }

        .block--categories-list{
            .categories--list{

                .list--container{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .list--item{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .item--icon{
                        position: relative;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        svg{
                            fill: #0056A5;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item--title{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #1E1E1E;
                        font-family: 'PT Sans Narrow';
                    }
                    .item--arrow{
                        position: relative;
                        width: 6px;
                        height: 8px;
                        margin-left: 7px;
                        svg{
                            fill: none;
                            stroke: #1E1E1E;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

        }

    }
}