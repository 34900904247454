.theme-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
        width: 36px;
        height: 28px;
        fill: #14A356;
        margin: 0 0 25px;
    }

    span {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        color: #333333;
        @include _992{
            font-size: 24px;
            line-height: 28px;
        }
    }
}


// Basic Modal Styles
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow-y: scroll;
}

.modal__container {
    background-color: #fff;
    padding: 50px 60px;
    max-height: 95vh;
    overflow: auto;
    max-width: 530px;
    @include _992{
        padding: 20px 15px;
        margin: 0 15px;
        overflow: scroll;
    }
}

.modal__header {}

.modal__content{
    .theme-policy{
        text-align: center;
    }
}

.modal__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: $text-clr;
    margin: 0 0 30px;
    &--center {
        text-align: center;
    }
    @include _992{
        font-size: 28px;
        line-height: 28px;
    }
}

.modal__subtitle {
    font-weight: normal;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $text-clr;
    margin: 20px 0 0;
    @include _992{
        font-size: 14px;
        line-height: 18px;
        margin: 12px 0 0;
        br{display: none;}
    }
}

.modal__close {
    position: absolute;
    cursor: pointer;
    border: none;
    font-size: 30px;
    line-height: 30px;
    background-color: transparent;
    top: 0;
    right: 0;
    padding: 8px 18px;
    color: $text-clr;
    transition: color $tr-time;

    &:hover {
        color: $main-clr-hover;
    }
    @include _992{
        padding: 0 10px;
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        // transform: translateY(15%);
    }

    to {
        // transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        // transform: translateY(0);
    }

    to {
        // transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}




.modal--root{

    .modal--overlay{
        display: none;
        position: fixed;
        overflow-y: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.65);
        z-index: 1000000;
    }

    &.is-open{
        .modal--overlay{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal {
    background: #FFFFFF;
    box-shadow: -4px 6px 16px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    position: relative;
    margin: auto;

    
    &--exit{
        position: absolute;
        top: 18px;
        right: 17px;
        width: 17px;
        height: 15px;
        cursor: pointer;

        svg{
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            stroke: #6A6A6A;
            width: 100%;
            height: 100%;
        }
    }

    &--header{
        font-family: 'PT Sans Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        color: #1E1E1E;
        text-align: center;
        @media(max-width: 1920px){
            font-size: calcFluidAll(34, 48);
            line-height: calcFluidAll(48, 62);
        }
    }

    &--container{
        padding: 50px 28px 63px 37px;
        @media(max-width: 1920px){
            padding-right: calcFluidAll(20, 28);
            padding-left: calcFluidAll(20, 37);
            padding-bottom: calcFluidAll(40, 63);
        }
    }

    &--field{
        height: 45px;
        background: #FFFFFF;
        border: 1px solid #D1D1D1;
        border-radius: 30px;
        padding-left: 24px;
        padding-right: 24px;
    }

    &--text{
        font-weight: 400;
        font-size: 21px;
        line-height: 145%;
        text-align: center;
    }

    &--submit{
        width: 100%;
        cursor: pointer;
        background: rgba(rgb(0, 86, 165), 0.7);
        border-radius: 55px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 125%;
        color: #FFFFFF;
        padding: 11px 11px 13px 11px;
        transition: all .2s linear;

        &.full_fill{
            background: #0056A5;
        }

    }

}

#modal--call {

    .modal {

        margin-top: 90px;
        margin-bottom: 90px;
        max-width: 380px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;

        &--header{
            margin-bottom: 30px;
        }

        &--submit{
            margin-bottom: 20px;
        }

        &--agree{
            font-weight: 300;
            font-size: 14px;
            line-height: 125%;
            text-align: center;
            color: rgba(18, 18, 18, 0.8);

            a{
                text-decoration-line: underline;
                color: #0056A5;
            }
        }

        &--phone{
            width: 100%;
            margin-bottom: 15px;
        }

    }

}

#modal--success {
    .modal{

        margin-top: 90px;
        margin-bottom: 90px;
        max-width: 380px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;

        &--message{

            .modal--image{
                width: 70px;
                height: 70px;
                margin: auto;
                margin-bottom: 30px;
            }

            .modal--header{
                margin-bottom: 10px;
            }

            .modal--text{
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #121212;
            }

        }

    }
}

#modal--error {
    .modal{
        margin-top: 90px;
        margin-bottom: 90px;

        max-width: 380px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        
        &--message{

            .modal--image{
                width: 70px;
                height: 70px;
                margin: auto;
                margin-bottom: 30px;
            }

            .modal--header{
                margin-bottom: 10px;
            }

            .modal--text{
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #121212;

                a {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 150%;
                    text-decoration-line: underline;
                    color: #9CB500;
                }

            }

        }

    }
}