.container{
	max-width: 1760px;
	margin: 0 auto;
	padding: 0 20px;

	@media(max-width: 1280px){
		padding: 0 10px;
	}

}

#page-container{
	padding: 0 0 100px;
	@include _992{
		padding: 0 0 60px;
	}
}

em{
	font-style: normal;
	color: $main-clr-hover;
}

.loading{
	// opacity: .4;
	filter: blur(3px);
	pointer-events: none;
	user-select: none;
}

.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;

	&--icon{
		background-color: transparent;
		transition: fill $tr-time;
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
		svg{
			width: 25px;
			height: 20px;
			fill: #B6B6B6;
		}
	}

	&--filled{
		transition: background-color $tr-time;
		background-color: $main-clr;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $alt-clr;
		}
		
		&:hover{
			background-color: $main-clr-hover;
		}
	}

	&--reverse{
		background-color: #fff;
		border: 1px solid $main-clr-hover;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $alt-clr;
			transition: color $tr-time;
		}
		&:hover{
			span{
				color: $main-clr-hover;
			}
		}
	}

	&--black{
		background-color: $alt-clr;
		transition: background-color $tr-time;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: #fff;
		}
		&:hover{
			background: #343434;
		}
	}

	&--border{
		border: 1px solid #282828;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: #282828;
		}
	}

	&--gray{
		background-color: #FFFFFF;
		border: 1px solid #D0D0D0;
		transition: border-color $tr-time;
		span{
			font-size: 15px;
			line-height: 18px;
			color: #282828;
		}
		&:hover{
			border-color: $main-clr-hover;
		}
	}

	&--poly{
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		background-color: #282828;
		font-weight: 500 !important;
		font-size: 12px !important;
		line-height: 14px !important;
		color: #FFFFFF !important;
		width: 22px;
		height: 20px;
		transform: scale(1);
		transition: transform $tr-time;
		&:empty{
			transform: scale(0);
		}
	}

	&--swal{
		padding: 15px 20px;
		margin: 0 5px;
	}
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}


.js-open-other{
	&.opened{
		.js-hidden{
			display: block;
		}
	}
	.js-hidden{
		display: none;
	}
}


.open-other{
	cursor: pointer;
	margin: 20px 0 0;
	&:hover{
		span{
			border-color: transparent;
		}
	}
	span{
		font-size: 15px;
		line-height: 18px;
		border-bottom: 1px solid #999999;
		color: #999999;
		transition: border-color $tr-time;
	}
}

.lead-phone{
	svg{
		width: 15px;
		height: 15px;
		fill: $main-clr-hover;
		margin-right: 8px;
	}
	span{
		font-size: 15px;
		line-height: 18px;
		color: $text-clr;
	}
}

.breadcrumbs{
	padding: 15px 0 35px;
	@include _992{
		padding: 10px 0 15px;
	}
	&-item{
		font-size: 12px;
		line-height: 18px;
		color: rgba(#6A6A6A, 0.8);
		@include _992{
			font-size: 12px;
			line-height: 16px;
		}

		&.item-main{
			color: #0056A5;
		}

	}
	&-back{
		display: block;
		margin: 15px 0 0;
		padding: 10px 0;
		&:hover{
			svg{
				fill: #0056A5;
			}
			span{
				color: #0056A5;
			}
		}
		svg{
			width: 16px;
			height: 10px;
			fill: $text-clr;
			margin-right: 5px;
			transition: fill $tr-time;
		}
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $text-clr;
			transition: color $tr-time;
		}
	}
}

.page-title{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: 0 0 35px;
	@include _992{
		margin: 0 0 25px;
	}
	&--line{
		align-items: center;
		justify-content: space-between;
	}
	&--notify{
		flex-direction: column;
	}
	&__name{
		margin-right: 15px;
		span, h1{
			font-weight: 700;
			font-size: 62px;
			line-height: 80px;
			color: #1E1E1E;
			font-family: 'PT Sans Narrow';
			@media(max-width: 1920px){
				font-size: calcFluidAll(24, 62);
				line-height: calcFluidAll(31, 80);
			}
		}
	}
	&__link{
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: $text-clr;
		position: relative;
		transition: color $tr-time;
		&:hover{
			color: $main-clr-hover;
		}
		&::before{
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background-color: $main-clr-hover;
			position: absolute;
			border-radius: 100%;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__content{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}
	&__notify{
		margin: 15px 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include _768{
			flex-direction: column;
    		align-items: flex-start;
		}
		&-text{
			font-size: 15px;
			line-height: 24px;
			color: #282828;
		}
		&-link{
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-decoration-line: underline;
			color: $main-clr-hover;			
		}
	}
}

.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-nav{
		cursor: pointer;
		&--next{
			svg{
				transform: rotate(180deg);
			}
		}
		svg{
			width: 36px;
			height: 25px;
			fill: #999999;
			transition: fill $tr-time;
		}
		&:hover{
			svg{
				fill: $alt-clr;
			}
		}
	}
	&-pagination{
		z-index: 1;
		&-bullet{
			margin: 0 10px;
			width: 10px;
			height: 10px;
			background-color: #6A6A6A;
			opacity: 1;
			transition: background-color $tr-time;
			&-active{
				background-color: $main-clr;
			}
			@include _576{
				width: 8px;
				height: 8px;
				margin: 0 3px;
			}
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.theme-counter{
    cursor: pointer;
    display: grid;
    grid-template-columns: 26px 1fr 26px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    &__minus,
    &__plus{
		user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
			width: 13px;
			height: 13px;
			fill: $text-clr;
			transition: fill $tr-time;
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
    }
    &__field{
        border: none;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: $text-clr;
    }
}

.swal2-title{
	@include _576{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _576{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _576{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}



.section--seo{
	margin-top: 120px;

	@media(max-width: 1920px){
		margin-top: calcFluidAll(60, 120);
	}

	.seo--left{
		margin-bottom: 33px;
	}
}

.tag--item{
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #1E1E1E;
	padding: 6px 20px 9px 20px;
	background: #EFF6FF;
	border-radius: 42px;
}

.button--style-fill{

	padding: 18px;
	padding-left: 54px;
	padding-right: 54px;

	font-weight: 400;
	font-size: 21px;
	line-height: 25px;
	text-align: center;

	color: #FFFFFF;

	background: #0056A5;
	border: 1px solid #0056A5;
	border-radius: 42px;

	transition: all .2s linear;

	cursor: pointer;

	&:hover{
		color: #0056A5;
		background: white;
	}

	@media(max-width: 1920px){
		font-size: calcFluidAll(18, 21);
		line-height: calcFluidAll(22, 25);

		padding-top: calcFluidAll(14, 18);
		padding-bottom: calcFluidAll(14, 18);
		padding-left: calcFluidAll(18, 54);
		padding-right: calcFluidAll(18, 54);
	}

}

.link--color-underline{
	color: #72B3FF;
	text-decoration-line: underline;
	&:hover{
		text-decoration-line: none;
	}
}

.button--style-blank{
	padding: 18px;
	padding-left: 54px;
	padding-right: 54px;
	font-weight: 400;
	font-size: 21px;
	line-height: 25px;
	color: #0056A5;
	background: white;
	border: 1px solid #0056A5;
	border-radius: 42px;
	transition: all .2s linear;
	cursor: pointer;
	text-decoration: none;

	@media(max-width: 1920px){
		font-size: calcFluidAll(18, 21);
		line-height: calcFluidAll(22, 25);

		padding-top: calcFluidAll(14, 18);
		padding-bottom: calcFluidAll(18, 18);
		padding-left: calcFluidAll(20, 54);
		padding-right: calcFluidAll(20, 54);
	}
	
	&:hover{
		color: white;
		background: #0056A5;
	}
}

.link--stilized{
	color: #0056A5;
	text-decoration: underline;

	&:hover{
		color: #0071DA;
		text-decoration: none;
	}

	&:visited{
		color: rgba(#0056A5, .65) !important;
	}
}



.componentVue {

	&--input{

		background: #fff;
		color: #121212;
		border: 1px solid #d1d1d1;
		border-radius: 30px;
		height: 45px;
		padding-left: 24px;
		padding-right: 24px;
		overflow: hidden;

		.input--container{
			position: relative;
			display: flex;
			align-items: center;
			height: 100%;
		}


		.input--field{
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
			bottom: 0;
			border: 0;	
		}

		.input--placeholder{
			position: absolute!important;
			pointer-events: none;
			top: auto;
			transition: all .2s linear;
			user-select: none;
		}

		&.isSuccess{
			border-color: #009317;
		}

		&.hasError{
			border-color: #E22828;
		}

		&.onFocus{
			border-color: #0056A5;
		}

		&.active{
			.input--placeholder{
				font-weight: 400!important;
				font-size: 10px!important;
				line-height: 125%!important;
				color: #868686!important;
				top: 5px;
			}
		}

	}

}