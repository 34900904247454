@mixin scrollStyles{
    position: relative;
    height: 500px;

    .simplebar-content{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .simplebar-scrollbar{
        z-index: 1;
        &::before{
            background: #0056A5;
            border-radius: 0;
            opacity: 1 !important;
            transition: none !important;
        }
    }

    .simplebar-track{

        &::after{
            content: "";
            position: absolute;
            background: #D9D9D9;
            right: 4px;
            left: 4px;
            top: 0;
            bottom: 0;
        }
    }

}

.header--block{
    display: flex;
    flex-direction: column;

    .header--top{

        padding-top: 18px;
        padding-bottom: 19px;
        background: white;

        height: 100px;

        .top--logo{
            max-width: 165px;
            width: 100%;
            margin-right: 10px;
            object-fit: contain;
        }

        .top--info{
            display: flex;
            gap: 45px;
            margin-left: 68px;

            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #1E1E1E;
            opacity: 0.75;

            @media(max-width: 1920px){
                gap: calcFluid(10, 45, 1280, 1920);
                font-size: calcFluid(17, 18, 1280, 1920);
                line-height: calcFluid(20, 22, 1280, 1920);
            }

            @media(max-width: 1659px){
                flex-direction: column;
                gap: calcFluid(10, 20, 1280, 1659);
            }

            @media (max-width: 1760px) {
                margin-left: 10px;
            }
        }

        .top--call_block{

            display: flex;
            flex-grow: 1;
            justify-content: end;
            align-items: center;
            gap: 47px;

            .call--phone{
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                
                color: #1E1E1E;
                
                opacity: 0.75;
            }

            .call--button{
                padding-left: 27px;
                padding-right: 27px;
            }
        }

        .top--container{
            display: flex;
            align-items: center;
            gap: 35px;
            @media(max-width: 1920px){
                gap: calcFluid(20, 35, 1280, 1920);
            }
        }

    }

    .header--bottom{

        background: #0056A5;
        border: solid 1px #0056A5;
        padding-top: 6px;
        padding-bottom: 9px;

        .bottom--catalog{

            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #FFFFFF;
            cursor: pointer;

            .catalog--title{
                font-family: 'PT Sans Narrow';
            }

            .catalog--icon{
                width: 29px;
                height: 15px;
                position: relative;

                svg{
                    position: absolute;
                    top: 0;
                    left: 0;
                    stroke: white;
                    width: 100%;
                    height: 100%;
                }

                .icon--active{
                    display: none;
                    width: 26px;
                    height: 16px;
                    stroke: white;
                }

            }

            &.active{
                .catalog--icon{

                    .icon--inactive{
                        display: none;
                    }

                    .icon--active{
                        display: block;
                    }

                }
            }

        }

        .block--search{
            flex-grow: 1;
            margin-left: 15px;
            margin-right: 15px;
            position: relative;

            .search--result{

                position: absolute;
                top: 100%;
                z-index: 14;
                background: white;
                left: 0;
                right: 0;
                padding: 23px;
                margin-top: 2px;
                border-radius: 15px;


                .search-line__item{

                    &:hover, &:hover span{
                        color: #0056a5;
                    }

                }

                display: none;
                &.opened{
                    display: block;
                }

                &.empty{
                    display: none !important;
                }
            }

        }

        .bottom--links{
            display: flex;
            gap: 65px;
            font-weight: 400;
            font-size: calcFluidDesktop(18, 22);
            line-height: 28px;
            color: #FFFFFF;
            align-items: center;

            .link--item{
                cursor: pointer;
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                color: #FFFFFF;

                .item--label{
                    font-family: 'PT Sans Narrow';
                }

                &:hover{
                    text-decoration: underline;
                }
            }

            .item--cart{

                display: flex;
                gap: 12px;
                position: relative;

                .cart--label{
                    font-family: 'PT Sans Narrow';
                }

                .cart--icon{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    svg{
                        position: absolute;
                        top: 0;
                        fill: none;
                        stroke: white;
                        width: 100%;
                        height: 100%;
                    }
                }

                .cart--divider{
                    border: 1px solid #FFFFFF;
                }

                .cart--price{
                    font-family: 'PT Sans Narrow';
                }

            }

            @media(max-width: 1920px){
                gap: calcFluid(25, 65, 1269, 1920);
            }

        }

        .bottom--container{
            display: flex;
            justify-content: space-between;
            gap: 15px;
        }

    }

    .block--cities{

        position: relative;

        .city--selected{

            display: flex;
            gap: 6px;
            align-items: center;
            cursor: pointer;

            .selected--icon{
                position: relative;
                width: 13px;
                height: 16px;
                svg{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    fill: none;
                }
            }

            .selected--label{
                font-weight: 400;
                font-size: 18px;
                line-height: 125%;
                color: #0056A5;
                border-bottom: 1px dashed #0056A5;
            }

            .selected--arrow{
                width: 13px;
                height: 4px;
                position: relative;
                svg{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    fill: none;
                    stroke: #0056A5;
                }
            }

            &.active{
                .selected--arrow{
                    transform: rotate(180deg);
                }
            }

        }

        .city--list{
            display: none;
            width: max-content;
            z-index: 10;
            position: absolute;
            background: #FFFFFF;
            border-radius: 0px 0px 20px 20px;
            padding: 17px 30px 20px 20px;

            .list--container{
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .list--item{
                font-weight: 400;
                font-size: 18px;
                line-height: 125%;
                color: #1E1E1E;
                max-width: 160px;
            }

            &.active{
                display: initial;
            }

        }
    }

    .block--search{
        border: 1px solid #FFFFFF;
        border-radius: 30px;
        padding-top: 9px;
        padding-bottom: 12px;
        padding-left: 20px;
        padding-right: 20px;

        .search--container{
            position: relative;
            display: flex;
            align-items: center;
            .search--input{
                border: 0;
                background: none;
                width: 100%;
                height: 100%;
                padding-right: 25px;

                &, &::placeholder {
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #FFFFFF;
                }
            }

            .search--submit{

                position: absolute;
                right: 0;
                cursor: pointer;

                width: 19px;
                height: 19px;
                svg{
                    width: 100%;
                    height: 100%;
                    fill: none;
                    stroke: white;
                    pointer-events: none;
                }
            }
        }
    }

    .desktop--block{

        &.block--categories{
            display: none;

            &.active{
                display: block;
            }

            .header--category-block{
                position: absolute;
                z-index: 7;

                & > .block--container{
                    position: relative;
                    z-index: 1;
                }

                .block--overlay{
                    content: "";
                    position: fixed;
                    background: #00000070;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                &::after{
                    content: "";
                    position: absolute;
                    background: white;
                    left: -100px;
                    right: -26px;
                    top: 0;
                    bottom: 0;
                }

            }

        }

    }

    @media(max-width: 1270px){
        display: none;
    }

    .cities--bottom{

        display: none;
        width: 300px;
        z-index: 10;
        position: absolute;
        background: #fff;
        border-radius: 0 0 20px 20px;
        padding: 17px 30px 20px 20px;
        top: 100px;
        left: 280px;

        .bottom{

            &--current{

                font-weight: bold;

                .current--city{
                    text-decoration: underline;
                }

            }

            &--inputs{

                display: flex;

                .input--element{
                    padding: 5px 10px;
                    cursor: pointer;
                }

                .input--accept{

                }

                .input--another{

                }
            }

        }

        &.active{
            display: block;
        }
    }

}

.header--category-block{

    background: white;

    .block--container{
        display: flex;
    }

    padding-top: 30px;
    padding-bottom: 30px;

    .block--categories{
        
        .categories--container{
            padding-right: 7px;
            width: 295px;

            .categories--list{
                display: flex;
                flex-direction: column;
                gap: 22px;
                padding-right: 10px;
                
                @include scrollStyles;

                .list--item{
                    display: flex;
                    gap: 35px;
                    align-items: center;
                    cursor: pointer;

                    .item--icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        fill: #0056A5;
                        width: 45px;
                        height: 45px;
                        svg{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .item--label{
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 32px;
                        color: #1E1E1E;
                        font-family: 'PT Sans Narrow';
                    }

                    &.active{
                        .item--label{
                            color: #0056A5;
                        }
                    }

                }

            }

        }
    }
    .categories--subcategories-scope{

        .scope--list{
            & > .list--item{

                width: 500px;
                display: none;
                position: relative;
                padding-right: 75px;

                @include scrollStyles;

                .item--close{

                    position: absolute;
                    cursor: pointer;
                    right: 20px;
                    top: 0;

                    width: 20px;
                    height: 20px;
                    svg{
                        width: 100%;
                        height: 100%;
                        stroke: #0056A5;
                    }
                }

                &.active{
                    display: block;
                }

                .item--subcategories{

                    .subcategories--container{
                        margin-left: 10px;

                        .subcategories--list{

                            display: flex;
                            gap: 22px;
                            flex-direction: column;

                            .list--item{
                                .item--label{
                                    font-weight: 400;
                                    font-size: 22px;
                                    line-height: 140%;
                                    color: #1E1E1E;
                                    font-family: 'PT Sans Narrow';
                                }
                            }
                        }

                    }

                }

            }

        }
    }
}