.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all; }

.swal2-popup.swal2-toast > * {
  grid-column: 2; }

.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center; }

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em; }

.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em; }

.swal2-popup.swal2-toast .swal2-html-container {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0; }

.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em; }

.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0; }

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: .5em;
  padding: 0 .5em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip .75s;
  animation: swal2-toast-animate-success-line-tip .75s; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long .75s;
  animation: swal2-toast-animate-success-line-long .75s; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show .5s;
  animation: swal2-toast-show .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide .1s forwards;
  animation: swal2-toast-hide .1s forwards; }

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: .625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4); }

.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important; }

.swal2-container.swal2-bottom-start, .swal2-container.swal2-center-start, .swal2-container.swal2-top-start {
  grid-template-columns: minmax(0, 1fr) auto auto; }

.swal2-container.swal2-bottom, .swal2-container.swal2-center, .swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-center-end, .swal2-container.swal2-top-end {
  grid-template-columns: auto auto minmax(0, 1fr); }

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start; }

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center; }

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end; }

.swal2-container.swal2-center-left > .swal2-popup, .swal2-container.swal2-center-start > .swal2-popup {
  grid-row: 2;
  align-self: center; }

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center; }

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end; }

.swal2-container.swal2-bottom-left > .swal2-popup, .swal2-container.swal2-bottom-start > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end; }

.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end; }

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end; }

.swal2-container.swal2-grow-fullscreen > .swal2-popup, .swal2-container.swal2-grow-row > .swal2-popup {
  grid-column: 1/4;
  width: 100%; }

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch; }

.swal2-container.swal2-no-transition {
  transition: none !important; }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0; }

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: .25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500; }

.swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5); }

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5); }

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5); }

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-styled:focus {
  outline: 0; }

.swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: .25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em; }

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color .1s,box-shadow .1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end; }

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #f27474; }

.swal2-close:focus {
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-close::-moz-focus-inner {
  border: 0; }

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word; }

.swal2-checkbox, .swal2-file, .swal2-input, .swal2-radio, .swal2-select, .swal2-textarea {
  margin: 1em 2em 0; }

.swal2-file, .swal2-input, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em; }

.swal2-file.swal2-inputerror, .swal2-input.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-file::-moz-placeholder, .swal2-input::-moz-placeholder, .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-file:-ms-input-placeholder, .swal2-input:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-file::placeholder, .swal2-input::placeholder, .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-range {
  margin: 1em 2em 0;
  background: #fff; }

.swal2-range input {
  width: 80%; }

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center; }

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 .75em; }

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-checkbox, .swal2-radio {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }

.swal2-checkbox label, .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-checkbox input, .swal2-radio input {
  flex-shrink: 0;
  margin: 0 .4em; }

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0; }

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: .625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300; }

.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  border: .25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-warning.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .5s;
  animation: swal2-animate-i-mark .5s; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-info.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .8s;
  animation: swal2-animate-i-mark .8s; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-question.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-question-mark .8s;
  animation: swal2-animate-question-mark .8s; }

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600; }

.swal2-progress-steps li {
  display: inline-block;
  position: relative; }

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6; }

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #2778c4; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@-webkit-keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@-webkit-keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@-webkit-keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none; }

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all; }

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important; } }

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none; }

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown .swal2-container.swal2-top-left, body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-center-left, body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-left, body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; }

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0; }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s 0.5s linear; }

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition-delay: 0s;
  transition-duration: 0s; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important; }

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0; }

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; }

/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-MediumItalic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("../fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-MediumItalic.woff") format("woff"), url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Italic.eot");
  src: local("Roboto Italic"), local("Roboto-Italic"), url("../fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-BoldItalic.eot");
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("../fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BoldItalic.woff") format("woff"), url("../fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-ThinItalic.eot");
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url("../fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-ThinItalic.woff") format("woff"), url("../fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Black.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-LightItalic.eot");
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-LightItalic.woff") format("woff"), url("../fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-BlackItalic.eot");
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url("../fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BlackItalic.woff") format("woff"), url("../fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Thin.eot");
  src: local("Roboto Thin"), local("Roboto-Thin"), url("../fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Thin.woff") format("woff"), url("../fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

body {
  min-height: 100vh;
  margin: 0;
  color: #282828;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  position: relative; }
  body.opened {
    overflow: hidden; }

main {
  position: relative;
  flex: 1 0 auto; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

ul, li {
  list-style-type: none; }

input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 18px;
  color: #999999; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

a {
  color: #282828;
  text-decoration: none;
  transition: color 0.3s; }
  a:hover {
    color: var(--main); }

svg {
  vertical-align: middle;
  transition: fill 0.3s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

* {
  font-family: "Golos Text"; }

.container {
  max-width: 1760px;
  margin: 0 auto;
  padding: 0 20px; }
  @media (max-width: 1280px) {
    .container {
      padding: 0 10px; } }

#page-container {
  padding: 0 0 100px; }
  @media (max-width: 991px) {
    #page-container {
      padding: 0 0 60px; } }

em {
  font-style: normal;
  color: #E5A701; }

.loading {
  filter: blur(3px);
  pointer-events: none;
  user-select: none; }

.btn {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn--icon {
    background-color: transparent;
    transition: fill 0.3s; }
    .btn--icon:hover svg {
      fill: #E5A701; }
    .btn--icon svg {
      width: 25px;
      height: 20px;
      fill: #B6B6B6; }
  .btn--filled {
    transition: background-color 0.3s;
    background-color: #FFD249; }
    .btn--filled span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #282828; }
    .btn--filled:hover {
      background-color: #E5A701; }
  .btn--reverse {
    background-color: #fff;
    border: 1px solid #E5A701; }
    .btn--reverse span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }
    .btn--reverse:hover span {
      color: #E5A701; }
  .btn--black {
    background-color: #282828;
    transition: background-color 0.3s; }
    .btn--black span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #fff; }
    .btn--black:hover {
      background: #343434; }
  .btn--border {
    border: 1px solid #282828; }
    .btn--border span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #282828; }
  .btn--gray {
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    transition: border-color 0.3s; }
    .btn--gray span {
      font-size: 15px;
      line-height: 18px;
      color: #282828; }
    .btn--gray:hover {
      border-color: #E5A701; }
  .btn--poly {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    background-color: #282828;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #FFFFFF !important;
    width: 22px;
    height: 20px;
    transform: scale(1);
    transition: transform 0.3s; }
    .btn--poly:empty {
      transform: scale(0); }
  .btn--swal {
    padding: 15px 20px;
    margin: 0 5px; }

.d-flex-row {
  display: flex;
  flex-direction: row; }

.js-open-other.opened .js-hidden {
  display: block; }

.js-open-other .js-hidden {
  display: none; }

.open-other {
  cursor: pointer;
  margin: 20px 0 0; }
  .open-other:hover span {
    border-color: transparent; }
  .open-other span {
    font-size: 15px;
    line-height: 18px;
    border-bottom: 1px solid #999999;
    color: #999999;
    transition: border-color 0.3s; }

.lead-phone svg {
  width: 15px;
  height: 15px;
  fill: #E5A701;
  margin-right: 8px; }

.lead-phone span {
  font-size: 15px;
  line-height: 18px;
  color: #282828; }

.breadcrumbs {
  padding: 15px 0 35px; }
  @media (max-width: 991px) {
    .breadcrumbs {
      padding: 10px 0 15px; } }
  .breadcrumbs-item {
    font-size: 12px;
    line-height: 18px;
    color: rgba(106, 106, 106, 0.8); }
    @media (max-width: 991px) {
      .breadcrumbs-item {
        font-size: 12px;
        line-height: 16px; } }
    .breadcrumbs-item.item-main {
      color: #0056A5; }
  .breadcrumbs-back {
    display: block;
    margin: 15px 0 0;
    padding: 10px 0; }
    .breadcrumbs-back:hover svg {
      fill: #0056A5; }
    .breadcrumbs-back:hover span {
      color: #0056A5; }
    .breadcrumbs-back svg {
      width: 16px;
      height: 10px;
      fill: #282828;
      margin-right: 5px;
      transition: fill 0.3s; }
    .breadcrumbs-back span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }

.page-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 0 35px; }
  @media (max-width: 991px) {
    .page-title {
      margin: 0 0 25px; } }
  .page-title--line {
    align-items: center;
    justify-content: space-between; }
  .page-title--notify {
    flex-direction: column; }
  .page-title__name {
    margin-right: 15px; }
    .page-title__name span, .page-title__name h1 {
      font-weight: 700;
      font-size: 62px;
      line-height: 80px;
      color: #1E1E1E;
      font-family: 'PT Sans Narrow'; }
      @media (max-width: 1920px) {
        .page-title__name span, .page-title__name h1 {
          font-size: calc(0.02375 * 100vw + 16.4px);
          line-height: calc(0.03062 * 100vw + 21.2px); } }
  .page-title__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #282828;
    position: relative;
    transition: color 0.3s; }
    .page-title__link:hover {
      color: #E5A701; }
    .page-title__link::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: #E5A701;
      position: absolute;
      border-radius: 100%;
      left: -15px;
      top: 0;
      bottom: 0;
      margin: auto; }
  .page-title__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
  .page-title__notify {
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 767px) {
      .page-title__notify {
        flex-direction: column;
        align-items: flex-start; } }
    .page-title__notify-text {
      font-size: 15px;
      line-height: 24px;
      color: #282828; }
    .page-title__notify-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #E5A701; }

.swiper {
  transition: opacity 0.3s;
  opacity: 1; }
  .swiper-init {
    opacity: 0; }
  .swiper-nav {
    cursor: pointer; }
    .swiper-nav--next svg {
      transform: rotate(180deg); }
    .swiper-nav svg {
      width: 36px;
      height: 25px;
      fill: #999999;
      transition: fill 0.3s; }
    .swiper-nav:hover svg {
      fill: #282828; }
  .swiper-pagination {
    z-index: 1; }
    .swiper-pagination-bullet {
      margin: 0 10px;
      width: 10px;
      height: 10px;
      background-color: #6A6A6A;
      opacity: 1;
      transition: background-color 0.3s; }
      .swiper-pagination-bullet-active {
        background-color: #FFD249; }
      @media (max-width: 575px) {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          margin: 0 3px; } }

.swiper-button-disabled {
  opacity: .6;
  pointer-events: none; }

.no-photo {
  background-image: url("../images/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.theme-counter {
  cursor: pointer;
  display: grid;
  grid-template-columns: 26px 1fr 26px;
  background: #FFFFFF;
  border: 1px solid #D0D0D0; }
  .theme-counter__minus, .theme-counter__plus {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    .theme-counter__minus svg, .theme-counter__plus svg {
      width: 13px;
      height: 13px;
      fill: #282828;
      transition: fill 0.3s; }
    .theme-counter__minus:hover svg, .theme-counter__plus:hover svg {
      fill: #E5A701; }
  .theme-counter__field {
    border: none;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: #282828; }

@media (max-width: 575px) {
  .swal2-title {
    padding: 10px 20px 0;
    font-size: 22px; } }

@media (max-width: 575px) {
  .swal2-html-container {
    font-size: 16px; } }

@media (max-width: 575px) {
  .swal2-actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin: 0;
    padding: 10px 15px 0; }
    .swal2-actions .btn--swal {
      width: 100%;
      margin: 0; } }

@media (max-width: 575px) {
  .swal2-icon {
    width: 50px;
    height: 50px; }
    .swal2-icon .swal2-icon-content {
      font-size: 30px; } }

.section--seo {
  margin-top: 120px; }
  @media (max-width: 1920px) {
    .section--seo {
      margin-top: calc(0.0375 * 100vw + 48px); } }
  .section--seo .seo--left {
    margin-bottom: 33px; }

.tag--item {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1E1E1E;
  padding: 6px 20px 9px 20px;
  background: #EFF6FF;
  border-radius: 42px; }

.button--style-fill {
  padding: 18px;
  padding-left: 54px;
  padding-right: 54px;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  background: #0056A5;
  border: 1px solid #0056A5;
  border-radius: 42px;
  transition: all .2s linear;
  cursor: pointer; }
  .button--style-fill:hover {
    color: #0056A5;
    background: white; }
  @media (max-width: 1920px) {
    .button--style-fill {
      font-size: calc(0.00187 * 100vw + 17.4px);
      line-height: calc(0.00187 * 100vw + 21.4px);
      padding-top: calc(0.0025 * 100vw + 13.2px);
      padding-bottom: calc(0.0025 * 100vw + 13.2px);
      padding-left: calc(0.0225 * 100vw + 10.8px);
      padding-right: calc(0.0225 * 100vw + 10.8px); } }

.link--color-underline {
  color: #72B3FF;
  text-decoration-line: underline; }
  .link--color-underline:hover {
    text-decoration-line: none; }

.button--style-blank {
  padding: 18px;
  padding-left: 54px;
  padding-right: 54px;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #0056A5;
  background: white;
  border: 1px solid #0056A5;
  border-radius: 42px;
  transition: all .2s linear;
  cursor: pointer;
  text-decoration: none; }
  @media (max-width: 1920px) {
    .button--style-blank {
      font-size: calc(0.00187 * 100vw + 17.4px);
      line-height: calc(0.00187 * 100vw + 21.4px);
      padding-top: calc(0.0025 * 100vw + 13.2px);
      padding-bottom: calc(0 * 100vw + 18px);
      padding-left: calc(0.02125 * 100vw + 13.2px);
      padding-right: calc(0.02125 * 100vw + 13.2px); } }
  .button--style-blank:hover {
    color: white;
    background: #0056A5; }

.link--stilized {
  color: #0056A5;
  text-decoration: underline; }
  .link--stilized:hover {
    color: #0071DA;
    text-decoration: none; }
  .link--stilized:visited {
    color: rgba(0, 86, 165, 0.65) !important; }

.componentVue--input {
  background: #fff;
  color: #121212;
  border: 1px solid #d1d1d1;
  border-radius: 30px;
  height: 45px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden; }
  .componentVue--input .input--container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%; }
  .componentVue--input .input--field {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0; }
  .componentVue--input .input--placeholder {
    position: absolute !important;
    pointer-events: none;
    top: auto;
    transition: all .2s linear;
    user-select: none; }
  .componentVue--input.isSuccess {
    border-color: #009317; }
  .componentVue--input.hasError {
    border-color: #E22828; }
  .componentVue--input.onFocus {
    border-color: #0056A5; }
  .componentVue--input.active .input--placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 125% !important;
    color: #868686 !important;
    top: 5px; }

.theme-form {
  position: relative; }

.theme-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffe6;
  z-index: 5; }
  .theme-loader img {
    width: 20%;
    height: 50%; }

.theme-input {
  display: block;
  position: relative;
  border: 1px solid #C9CED8;
  background-color: #fff;
  padding: 20px 15px 0;
  width: 100%;
  transition-property: border-color;
  transition-duration: 0.3s;
  cursor: text; }
  @media (max-width: 767px) {
    .theme-input {
      height: 50px; } }
  .theme-input:not(:last-child) {
    margin: 0 0 14px; }
  .theme-input.error {
    border-color: #ff2828; }
    .theme-input.error .theme-input__placeholder {
      color: #ff2828; }
  .theme-input__field {
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: #282828;
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1; }
    .theme-input__field::placeholder {
      opacity: 0; }
    .theme-input__field:disabled {
      opacity: .6; }
    .theme-input__field:focus + span,
    .theme-input__field:not(:placeholder-shown) + span {
      top: 6px;
      font-size: 12px;
      line-height: 14px; }
  .theme-input__placeholder {
    position: absolute;
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: #878787;
    left: 15px;
    top: calc(50% - 10px);
    transition-property: font-size, top, color;
    transition-duration: 0.3s; }
    @media (max-width: 575px) {
      .theme-input__placeholder {
        font-size: 14px;
        line-height: 22px; } }
  .theme-input__password-toggle {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: calc(50% - 12px);
    fill: #999999;
    cursor: pointer;
    transition: fill 0.3s;
    z-index: 3; }
    .theme-input__password-toggle.active {
      fill: #E5A701; }

.theme-textarea__field {
  resize: none;
  height: 150px; }

.theme-textarea__placeholder {
  top: 15px; }

.theme-toggle {
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center; }
  .theme-toggle:not(:last-child) {
    margin: 0 0 10px; }
  .theme-toggle:hover .theme-toggle__name {
    color: #E5A701; }
  .theme-toggle__input {
    display: none; }
    .theme-toggle__input:checked + div {
      background-color: #282828;
      border-color: #282828; }
      .theme-toggle__input:checked + div::before {
        right: 3px;
        border-color: #282828; }
  .theme-toggle__fake {
    position: relative;
    width: 50px;
    height: 28px;
    background-color: #E3E3E3;
    border: 1px solid #D0D0D0;
    transition: 0.3s; }
    .theme-toggle__fake::before {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: calc(100% - 4px);
      background-color: #FFFFFF;
      border: 1px solid #D0D0D0;
      top: 2px;
      right: calc(100% - 50% - 3px);
      transition: 0.3s; }
  .theme-toggle__name {
    font-size: 14px;
    line-height: 18px;
    color: #282828;
    transition: color 0.3s; }

.choice-block {
  cursor: pointer;
  user-select: none; }
  .choice-block__field {
    display: none; }
    .choice-block__field:checked + .choice-block__front {
      border-color: #D0D0D0;
      background-color: transparent; }
      .choice-block__field:checked + .choice-block__front .choice-block__fake {
        background-color: #282828;
        border-color: #282828; }
        .choice-block__field:checked + .choice-block__front .choice-block__fake svg {
          fill: #FFFFFF; }
  .choice-block__front {
    background-color: #F8F8F8;
    border: 1px solid transparent;
    padding: 20px 18px;
    transition: 0.3s;
    width: 100%;
    height: 100%; }
  .choice-block__fake {
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s; }
    .choice-block__fake svg {
      width: 12px;
      height: 9px;
      fill: transparent;
      transition: fill 0.3s; }
  .choice-block__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin: 12px 0 0; }
  .choice-block__desc {
    font-size: 14px;
    line-height: 18px;
    color: #7D7D7D;
    margin: 8px 0 0; }
  .choice-block__link {
    padding: 10px 0 0;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline; }
    .choice-block__link:hover {
      color: #E5A701; }

.theme-checkbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  user-select: none; }
  .theme-checkbox__field {
    display: none; }
    .theme-checkbox__field:checked + div {
      background-color: #282828;
      border-color: #282828; }
      .theme-checkbox__field:checked + div svg {
        opacity: 1; }
  .theme-checkbox__fake {
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    transition: 0.3s; }
    .theme-checkbox__fake svg {
      width: 12px;
      height: 9px;
      fill: #fff;
      opacity: 0;
      transition: opacity 0.3s; }
  .theme-checkbox__name {
    font-size: 14px;
    line-height: 18px;
    color: #282828; }

.theme-submit {
  width: 100%;
  height: 45px; }

.g-recaptcha {
  overflow: hidden; }
  .g-recaptcha:not(:last-child) {
    margin: 0 0 14px; }

.theme-policy {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin: 12px 0 0; }
  .theme-policy a {
    color: #E5A701; }

.noUi-horizontal {
  height: 4px; }

.noUi-target {
  background: #999999;
  border: none;
  box-shadow: none;
  margin: 0 10px; }

.noUi-connect {
  background-color: #282828; }

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border: none;
  background: #282828;
  border-radius: 50%;
  box-shadow: none;
  right: -7px;
  top: -6px; }
  .noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
    display: none; }

.noUi-touch-area {
  display: none; }

.radio-block {
  cursor: pointer; }
  .radio-block:not(:last-child) {
    margin-right: 10px; }
  .radio-block__field {
    display: none; }
    .radio-block__field:checked + span {
      border-color: #E5A701; }
  .radio-block__name {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    padding: 5px 15px;
    transition: border-color 0.3s; }
    .radio-block__name:hover {
      border-color: #282828; }

.theme-select {
  position: relative; }
  .theme-select__list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 15px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #c9ced8;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 4px 6px 8px 0px rgba(35, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    transition: 0.3s;
    z-index: 3; }
    .theme-select__list.opened {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 5px); }
    .theme-select__list label {
      cursor: pointer;
      padding: 3px 10px;
      transition: background-color 0.3s; }
      .theme-select__list label:hover {
        background-color: #eaeaea; }
      .theme-select__list label input {
        display: none; }
      .theme-select__list label span {
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        white-space: nowrap; }

.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .theme-success svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px; }
  .theme-success span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333; }
    @media (max-width: 991px) {
      .theme-success span {
        font-size: 24px;
        line-height: 28px; } }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: scroll; }

.modal__container {
  background-color: #fff;
  padding: 50px 60px;
  max-height: 95vh;
  overflow: auto;
  max-width: 530px; }
  @media (max-width: 991px) {
    .modal__container {
      padding: 20px 15px;
      margin: 0 15px;
      overflow: scroll; } }

.modal__content .theme-policy {
  text-align: center; }

.modal__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  color: #282828;
  margin: 0 0 30px; }
  .modal__title--center {
    text-align: center; }
  @media (max-width: 991px) {
    .modal__title {
      font-size: 28px;
      line-height: 28px; } }

.modal__subtitle {
  font-weight: normal;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #282828;
  margin: 20px 0 0; }
  @media (max-width: 991px) {
    .modal__subtitle {
      font-size: 14px;
      line-height: 18px;
      margin: 12px 0 0; }
      .modal__subtitle br {
        display: none; } }

.modal__close {
  position: absolute;
  cursor: pointer;
  border: none;
  font-size: 30px;
  line-height: 30px;
  background-color: transparent;
  top: 0;
  right: 0;
  padding: 8px 18px;
  color: #282828;
  transition: color 0.3s; }
  .modal__close:hover {
    color: #E5A701; }
  @media (max-width: 991px) {
    .modal__close {
      padding: 0 10px; } }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from { }
  to { } }

@keyframes mmslideOut {
  from { }
  to { } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform; }

.modal--root .modal--overlay {
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1000000; }

.modal--root.is-open .modal--overlay {
  display: flex;
  justify-content: center;
  align-items: center; }

.modal {
  background: #FFFFFF;
  box-shadow: -4px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  position: relative;
  margin: auto; }
  .modal--exit {
    position: absolute;
    top: 18px;
    right: 17px;
    width: 17px;
    height: 15px;
    cursor: pointer; }
    .modal--exit svg {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      stroke: #6A6A6A;
      width: 100%;
      height: 100%; }
  .modal--header {
    font-family: 'PT Sans Narrow';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
    color: #1E1E1E;
    text-align: center; }
    @media (max-width: 1920px) {
      .modal--header {
        font-size: calc(0.00875 * 100vw + 31.2px);
        line-height: calc(0.00875 * 100vw + 45.2px); } }
  .modal--container {
    padding: 50px 28px 63px 37px; }
    @media (max-width: 1920px) {
      .modal--container {
        padding-right: calc(0.005 * 100vw + 18.4px);
        padding-left: calc(0.01063 * 100vw + 16.6px);
        padding-bottom: calc(0.01438 * 100vw + 35.4px); } }
  .modal--field {
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 30px;
    padding-left: 24px;
    padding-right: 24px; }
  .modal--text {
    font-weight: 400;
    font-size: 21px;
    line-height: 145%;
    text-align: center; }
  .modal--submit {
    width: 100%;
    cursor: pointer;
    background: rgba(0, 86, 165, 0.7);
    border-radius: 55px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 125%;
    color: #FFFFFF;
    padding: 11px 11px 13px 11px;
    transition: all .2s linear; }
    .modal--submit.full_fill {
      background: #0056A5; }

#modal--call .modal {
  margin-top: 90px;
  margin-bottom: 90px;
  max-width: 380px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%; }
  #modal--call .modal--header {
    margin-bottom: 30px; }
  #modal--call .modal--submit {
    margin-bottom: 20px; }
  #modal--call .modal--agree {
    font-weight: 300;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: rgba(18, 18, 18, 0.8); }
    #modal--call .modal--agree a {
      text-decoration-line: underline;
      color: #0056A5; }
  #modal--call .modal--phone {
    width: 100%;
    margin-bottom: 15px; }

#modal--success .modal {
  margin-top: 90px;
  margin-bottom: 90px;
  max-width: 380px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%; }
  #modal--success .modal--message .modal--image {
    width: 70px;
    height: 70px;
    margin: auto;
    margin-bottom: 30px; }
  #modal--success .modal--message .modal--header {
    margin-bottom: 10px; }
  #modal--success .modal--message .modal--text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #121212; }

#modal--error .modal {
  margin-top: 90px;
  margin-bottom: 90px;
  max-width: 380px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%; }
  #modal--error .modal--message .modal--image {
    width: 70px;
    height: 70px;
    margin: auto;
    margin-bottom: 30px; }
  #modal--error .modal--message .modal--header {
    margin-bottom: 10px; }
  #modal--error .modal--message .modal--text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #121212; }
    #modal--error .modal--message .modal--text a {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #9CB500; }

.header--block {
  display: flex;
  flex-direction: column; }
  .header--block .header--top {
    padding-top: 18px;
    padding-bottom: 19px;
    background: white;
    height: 100px; }
    .header--block .header--top .top--logo {
      max-width: 165px;
      width: 100%;
      margin-right: 10px;
      object-fit: contain; }
    .header--block .header--top .top--info {
      display: flex;
      gap: 45px;
      margin-left: 68px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #1E1E1E;
      opacity: 0.75; }
      @media (max-width: 1920px) {
        .header--block .header--top .top--info {
          gap: calc(0.05469 * 100vw + -60px);
          font-size: calc(0.00156 * 100vw + 15px);
          line-height: calc(0.00313 * 100vw + 16px); } }
      @media (max-width: 1659px) {
        .header--block .header--top .top--info {
          flex-direction: column;
          gap: calc(0.02639 * 100vw + -23.77309px); } }
      @media (max-width: 1760px) {
        .header--block .header--top .top--info {
          margin-left: 10px; } }
    .header--block .header--top .top--call_block {
      display: flex;
      flex-grow: 1;
      justify-content: end;
      align-items: center;
      gap: 47px; }
      .header--block .header--top .top--call_block .call--phone {
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        color: #1E1E1E;
        opacity: 0.75; }
      .header--block .header--top .top--call_block .call--button {
        padding-left: 27px;
        padding-right: 27px; }
    .header--block .header--top .top--container {
      display: flex;
      align-items: center;
      gap: 35px; }
      @media (max-width: 1920px) {
        .header--block .header--top .top--container {
          gap: calc(0.02344 * 100vw + -10px); } }
  .header--block .header--bottom {
    background: #0056A5;
    border: solid 1px #0056A5;
    padding-top: 6px;
    padding-bottom: 9px; }
    .header--block .header--bottom .bottom--catalog {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #FFFFFF;
      cursor: pointer; }
      .header--block .header--bottom .bottom--catalog .catalog--title {
        font-family: 'PT Sans Narrow'; }
      .header--block .header--bottom .bottom--catalog .catalog--icon {
        width: 29px;
        height: 15px;
        position: relative; }
        .header--block .header--bottom .bottom--catalog .catalog--icon svg {
          position: absolute;
          top: 0;
          left: 0;
          stroke: white;
          width: 100%;
          height: 100%; }
        .header--block .header--bottom .bottom--catalog .catalog--icon .icon--active {
          display: none;
          width: 26px;
          height: 16px;
          stroke: white; }
      .header--block .header--bottom .bottom--catalog.active .catalog--icon .icon--inactive {
        display: none; }
      .header--block .header--bottom .bottom--catalog.active .catalog--icon .icon--active {
        display: block; }
    .header--block .header--bottom .block--search {
      flex-grow: 1;
      margin-left: 15px;
      margin-right: 15px;
      position: relative; }
      .header--block .header--bottom .block--search .search--result {
        position: absolute;
        top: 100%;
        z-index: 14;
        background: white;
        left: 0;
        right: 0;
        padding: 23px;
        margin-top: 2px;
        border-radius: 15px;
        display: none; }
        .header--block .header--bottom .block--search .search--result .search-line__item:hover, .header--block .header--bottom .block--search .search--result .search-line__item:hover span {
          color: #0056a5; }
        .header--block .header--bottom .block--search .search--result.opened {
          display: block; }
        .header--block .header--bottom .block--search .search--result.empty {
          display: none !important; }
    .header--block .header--bottom .bottom--links {
      display: flex;
      gap: 65px;
      font-weight: 400;
      font-size: calc(0.00625 * 100vw + 10px);
      line-height: 28px;
      color: #FFFFFF;
      align-items: center; }
      .header--block .header--bottom .bottom--links .link--item {
        cursor: pointer;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        color: #FFFFFF; }
        .header--block .header--bottom .bottom--links .link--item .item--label {
          font-family: 'PT Sans Narrow'; }
        .header--block .header--bottom .bottom--links .link--item:hover {
          text-decoration: underline; }
      .header--block .header--bottom .bottom--links .item--cart {
        display: flex;
        gap: 12px;
        position: relative; }
        .header--block .header--bottom .bottom--links .item--cart .cart--label {
          font-family: 'PT Sans Narrow'; }
        .header--block .header--bottom .bottom--links .item--cart .cart--icon {
          position: relative;
          width: 24px;
          height: 24px; }
          .header--block .header--bottom .bottom--links .item--cart .cart--icon svg {
            position: absolute;
            top: 0;
            fill: none;
            stroke: white;
            width: 100%;
            height: 100%; }
        .header--block .header--bottom .bottom--links .item--cart .cart--divider {
          border: 1px solid #FFFFFF; }
        .header--block .header--bottom .bottom--links .item--cart .cart--price {
          font-family: 'PT Sans Narrow'; }
      @media (max-width: 1920px) {
        .header--block .header--bottom .bottom--links {
          gap: calc(0.06144 * 100vw + -52.97235px); } }
    .header--block .header--bottom .bottom--container {
      display: flex;
      justify-content: space-between;
      gap: 15px; }
  .header--block .block--cities {
    position: relative; }
    .header--block .block--cities .city--selected {
      display: flex;
      gap: 6px;
      align-items: center;
      cursor: pointer; }
      .header--block .block--cities .city--selected .selected--icon {
        position: relative;
        width: 13px;
        height: 16px; }
        .header--block .block--cities .city--selected .selected--icon svg {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          fill: none; }
      .header--block .block--cities .city--selected .selected--label {
        font-weight: 400;
        font-size: 18px;
        line-height: 125%;
        color: #0056A5;
        border-bottom: 1px dashed #0056A5; }
      .header--block .block--cities .city--selected .selected--arrow {
        width: 13px;
        height: 4px;
        position: relative; }
        .header--block .block--cities .city--selected .selected--arrow svg {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          fill: none;
          stroke: #0056A5; }
      .header--block .block--cities .city--selected.active .selected--arrow {
        transform: rotate(180deg); }
    .header--block .block--cities .city--list {
      display: none;
      width: max-content;
      z-index: 10;
      position: absolute;
      background: #FFFFFF;
      border-radius: 0px 0px 20px 20px;
      padding: 17px 30px 20px 20px; }
      .header--block .block--cities .city--list .list--container {
        display: flex;
        flex-direction: column;
        gap: 20px; }
      .header--block .block--cities .city--list .list--item {
        font-weight: 400;
        font-size: 18px;
        line-height: 125%;
        color: #1E1E1E;
        max-width: 160px; }
      .header--block .block--cities .city--list.active {
        display: initial; }
  .header--block .block--search {
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    padding-top: 9px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px; }
    .header--block .block--search .search--container {
      position: relative;
      display: flex;
      align-items: center; }
      .header--block .block--search .search--container .search--input {
        border: 0;
        background: none;
        width: 100%;
        height: 100%;
        padding-right: 25px; }
        .header--block .block--search .search--container .search--input, .header--block .block--search .search--container .search--input::placeholder {
          font-weight: 400;
          font-size: 22px;
          line-height: 28px;
          color: #FFFFFF; }
      .header--block .block--search .search--container .search--submit {
        position: absolute;
        right: 0;
        cursor: pointer;
        width: 19px;
        height: 19px; }
        .header--block .block--search .search--container .search--submit svg {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: white;
          pointer-events: none; }
  .header--block .desktop--block.block--categories {
    display: none; }
    .header--block .desktop--block.block--categories.active {
      display: block; }
    .header--block .desktop--block.block--categories .header--category-block {
      position: absolute;
      z-index: 7; }
      .header--block .desktop--block.block--categories .header--category-block > .block--container {
        position: relative;
        z-index: 1; }
      .header--block .desktop--block.block--categories .header--category-block .block--overlay {
        content: "";
        position: fixed;
        background: #00000070;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .header--block .desktop--block.block--categories .header--category-block::after {
        content: "";
        position: absolute;
        background: white;
        left: -100px;
        right: -26px;
        top: 0;
        bottom: 0; }
  @media (max-width: 1270px) {
    .header--block {
      display: none; } }
  .header--block .cities--bottom {
    display: none;
    width: 300px;
    z-index: 10;
    position: absolute;
    background: #fff;
    border-radius: 0 0 20px 20px;
    padding: 17px 30px 20px 20px;
    top: 100px;
    left: 280px; }
    .header--block .cities--bottom .bottom--current {
      font-weight: bold; }
      .header--block .cities--bottom .bottom--current .current--city {
        text-decoration: underline; }
    .header--block .cities--bottom .bottom--inputs {
      display: flex; }
      .header--block .cities--bottom .bottom--inputs .input--element {
        padding: 5px 10px;
        cursor: pointer; }
    .header--block .cities--bottom.active {
      display: block; }

.header--category-block {
  background: white;
  padding-top: 30px;
  padding-bottom: 30px; }
  .header--category-block .block--container {
    display: flex; }
  .header--category-block .block--categories .categories--container {
    padding-right: 7px;
    width: 295px; }
    .header--category-block .block--categories .categories--container .categories--list {
      display: flex;
      flex-direction: column;
      gap: 22px;
      padding-right: 10px;
      position: relative;
      height: 500px; }
      .header--category-block .block--categories .categories--container .categories--list .simplebar-content {
        display: flex;
        flex-direction: column;
        gap: 20px; }
      .header--category-block .block--categories .categories--container .categories--list .simplebar-scrollbar {
        z-index: 1; }
        .header--category-block .block--categories .categories--container .categories--list .simplebar-scrollbar::before {
          background: #0056A5;
          border-radius: 0;
          opacity: 1 !important;
          transition: none !important; }
      .header--category-block .block--categories .categories--container .categories--list .simplebar-track::after {
        content: "";
        position: absolute;
        background: #D9D9D9;
        right: 4px;
        left: 4px;
        top: 0;
        bottom: 0; }
      .header--category-block .block--categories .categories--container .categories--list .list--item {
        display: flex;
        gap: 35px;
        align-items: center;
        cursor: pointer; }
        .header--category-block .block--categories .categories--container .categories--list .list--item .item--icon {
          display: flex;
          justify-content: center;
          align-items: center;
          fill: #0056A5;
          width: 45px;
          height: 45px; }
          .header--category-block .block--categories .categories--container .categories--list .list--item .item--icon svg {
            max-width: 100%;
            max-height: 100%; }
        .header--category-block .block--categories .categories--container .categories--list .list--item .item--label {
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          color: #1E1E1E;
          font-family: 'PT Sans Narrow'; }
        .header--category-block .block--categories .categories--container .categories--list .list--item.active .item--label {
          color: #0056A5; }
  .header--category-block .categories--subcategories-scope .scope--list > .list--item {
    width: 500px;
    display: none;
    position: relative;
    padding-right: 75px;
    position: relative;
    height: 500px; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item .simplebar-content {
      display: flex;
      flex-direction: column;
      gap: 20px; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item .simplebar-scrollbar {
      z-index: 1; }
      .header--category-block .categories--subcategories-scope .scope--list > .list--item .simplebar-scrollbar::before {
        background: #0056A5;
        border-radius: 0;
        opacity: 1 !important;
        transition: none !important; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item .simplebar-track::after {
      content: "";
      position: absolute;
      background: #D9D9D9;
      right: 4px;
      left: 4px;
      top: 0;
      bottom: 0; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item .item--close {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 0;
      width: 20px;
      height: 20px; }
      .header--category-block .categories--subcategories-scope .scope--list > .list--item .item--close svg {
        width: 100%;
        height: 100%;
        stroke: #0056A5; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item.active {
      display: block; }
    .header--category-block .categories--subcategories-scope .scope--list > .list--item .item--subcategories .subcategories--container {
      margin-left: 10px; }
      .header--category-block .categories--subcategories-scope .scope--list > .list--item .item--subcategories .subcategories--container .subcategories--list {
        display: flex;
        gap: 22px;
        flex-direction: column; }
        .header--category-block .categories--subcategories-scope .scope--list > .list--item .item--subcategories .subcategories--container .subcategories--list .list--item .item--label {
          font-weight: 400;
          font-size: 22px;
          line-height: 140%;
          color: #1E1E1E;
          font-family: 'PT Sans Narrow'; }

@media (max-width: 1269px) {
  header {
    height: 50px; }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 10; } }

.header--block.block--mobile {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14); }
  @media (max-width: 1270px) {
    .header--block.block--mobile {
      display: block; } }
  .header--block.block--mobile .header--top {
    padding: 0;
    height: 100%; }
  .header--block.block--mobile .top--logo {
    padding-top: 9px;
    padding-bottom: 9px;
    max-width: 100px;
    display: flex;
    align-items: center; }
    .header--block.block--mobile .top--logo img {
      width: 100%; }
  .header--block.block--mobile .top--container {
    justify-content: space-between;
    padding-left: 21px;
    align-items: initial; }
  .header--block.block--mobile .mobile--container {
    display: flex; }
  .header--block.block--mobile .mobile--element {
    height: 100%;
    width: 50px;
    position: relative; }
  .header--block.block--mobile .mobile--cart {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .header--block.block--mobile .mobile--cart svg {
      position: absolute;
      width: 18px;
      height: 18px;
      fill: #0056A5;
      stroke: #0056a5; }
    .header--block.block--mobile .mobile--cart .cart--value {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: absolute;
      z-index: 1;
      width: 14px;
      height: 14px;
      text-align: center;
      background: black;
      color: white;
      border-radius: 100%;
      font-size: 10px;
      top: 13px;
      right: 10px;
      font-family: 'PT Sans Narrow'; }
  .header--block.block--mobile .mobile--call {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .header--block.block--mobile .mobile--call svg {
      position: absolute;
      width: 18px;
      height: 18px;
      fill: #0056A5;
      pointer-events: none; }
  .header--block.block--mobile .mobile--open-list {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .header--block.block--mobile .mobile--open-list .svg-default {
      position: absolute;
      width: 26px;
      height: 16px;
      stroke: #0056A5; }
    .header--block.block--mobile .mobile--open-list .svg-open {
      display: none;
      position: absolute;
      width: 26px;
      height: 16px;
      stroke: #A9A9A9; }
    .header--block.block--mobile .mobile--open-list.open .svg-default {
      display: none; }
    .header--block.block--mobile .mobile--open-list.open .svg-open {
      display: block; }

.header--block.block--sidebar {
  display: none;
  padding-top: 20px;
  background: white; }
  .header--block.block--sidebar.open {
    display: block; }
  .header--block.block--sidebar .block--search-container .block--search {
    border-color: #A9A9A9;
    margin-bottom: 20px;
    margin-left: 21px;
    margin-right: 21px;
    position: relative; }
    .header--block.block--sidebar .block--search-container .block--search .search--input, .header--block.block--sidebar .block--search-container .block--search .search--input::placeholder {
      font-weight: 400;
      font-size: 12px;
      font-family: 'PT Sans Narrow';
      line-height: 125%;
      color: #636363; }
    .header--block.block--sidebar .block--search-container .block--search .search--submit svg {
      stroke: #636363; }
  .header--block.block--sidebar .block--search-container .search--result {
    margin-left: 21px;
    margin-right: 21px;
    margin-bottom: 25px;
    padding-left: 20px;
    display: none; }
    .header--block.block--sidebar .block--search-container .search--result .search-line__item:hover, .header--block.block--sidebar .block--search-container .search--result .search-line__item:hover span {
      color: #0056a5; }
    .header--block.block--sidebar .block--search-container .search--result.opened {
      display: block; }
    .header--block.block--sidebar .block--search-container .search--result.empty {
      display: none !important; }
  .header--block.block--sidebar .sidebar--container .sidebar--dynamic {
    position: relative; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--container {
      display: flex;
      flex-direction: column;
      padding: 0px 21px 30px 21px; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--city-selected {
      margin-bottom: 20px; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--categories-list {
      margin-bottom: 30px; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--address {
      margin-bottom: 5px; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--worktime {
      margin-bottom: 10px; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--call {
      display: flex;
      flex-direction: column;
      gap: 24px; }
      .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--call .call--phone {
        display: flex;
        align-items: center;
        gap: 8px; }
        .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--call .call--phone .phone--icon {
          position: relative;
          width: 18px;
          height: 18px; }
          .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--call .call--phone .phone--icon svg {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            fill: #0056A5; }
        .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--item.item--contacts .contact--item.item--call .call--phone .phone--value {
          font-weight: 500;
          font-size: 24px;
          line-height: 125%;
          color: #1E1E1E;
          font-family: 'PT Sans Narrow'; }
    .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      overflow: hidden; }
      .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item {
        padding: 0px 21px 30px 21px;
        position: absolute;
        left: 100%;
        right: -100%;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: all;
        transition: all .2s linear;
        background: white; }
        .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item.active {
          left: 0;
          right: 0; }
        .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item .item--container {
          display: flex;
          flex-direction: column; }
        .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item .item--prev {
          display: flex;
          gap: 5px;
          align-items: center;
          margin-bottom: 20px;
          cursor: pointer; }
          .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item .item--prev .prev--arrow {
            position: relative;
            width: 12px;
            height: 10px; }
            .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item .item--prev .prev--arrow svg {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              top: 0;
              stroke: #272727; }
          .header--block.block--sidebar .sidebar--container .sidebar--dynamic .dynamic--move_container .move--item .item--prev .prev--label {
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            color: #272727;
            font-family: 'PT Sans Narrow'; }
  .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--container {
    display: flex;
    flex-direction: column;
    gap: 20px; }
  .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item .item--icon {
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 10px; }
      .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item .item--icon svg {
        fill: #0056A5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item .item--title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #1E1E1E;
      font-family: 'PT Sans Narrow'; }
    .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item .item--arrow {
      position: relative;
      width: 6px;
      height: 8px;
      margin-left: 7px; }
      .header--block.block--sidebar .sidebar--container .block--categories-list .categories--list .list--item .item--arrow svg {
        fill: none;
        stroke: #1E1E1E;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }

.footer {
  margin-top: 100px; }
  .footer--block {
    background: #000D1A;
    padding-top: 88px;
    padding-bottom: 30px; }
  .footer--container {
    display: flex;
    flex-direction: column; }
  .footer--form {
    position: relative; }
    .footer--form .form--block {
      width: 635px;
      display: flex;
      flex-direction: column;
      margin-left: 145px; }
      .footer--form .form--block .block--title {
        font-weight: 700;
        font-size: 62px;
        line-height: 80px;
        color: #FFFFFF;
        margin-bottom: 22px; }
        @media (max-width: 1920px) {
          .footer--form .form--block .block--title {
            font-size: calc(0.02625 * 100vw + 11.6px);
            line-height: calc(0.025 * 100vw + 12px); } }
      .footer--form .form--block .block--description {
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: #FFFFFF;
        margin-bottom: 54px; }
        @media (max-width: 1920px) {
          .footer--form .form--block .block--description {
            font-size: calc(0.005 * 100vw + 14.4px); } }
      .footer--form .form--block .block--inputs {
        display: flex;
        gap: 30px;
        margin-bottom: 40px; }
        .footer--form .form--block .block--inputs .input--element {
          width: 303px; }
        .footer--form .form--block .block--inputs .input--phone {
          border: 1px solid #FFFFFF;
          border-radius: 55px;
          background: none;
          padding-left: 34px;
          padding-right: 34px; }
          .footer--form .form--block .block--inputs .input--phone, .footer--form .form--block .block--inputs .input--phone::placeholder {
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;
            color: #FFFFFF; }
      .footer--form .form--block .block--agree {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF; }
        .footer--form .form--block .block--agree .agree--link {
          color: #72B3FF;
          text-decoration-line: underline; }
          .footer--form .form--block .block--agree .agree--link:hover {
            text-decoration-line: none; }
    .footer--form .form--background {
      position: absolute;
      left: 0;
      right: 30px;
      top: -280px;
      bottom: 0;
      pointer-events: none; }
      .footer--form .form--background .background--image {
        position: absolute;
        right: -100px;
        bottom: -136px;
        pointer-events: none; }
      @media (max-width: 1919px) {
        .footer--form .form--background {
          overflow: hidden; } }
    @media (max-width: 1670px) {
      .footer--form .form--block {
        margin-left: 50px; }
      .footer--form .form--background .background--image {
        object-fit: contain;
        right: -160px; } }
    @media (max-width: 1500px) {
      .footer--form .form--block {
        width: 400px;
        margin-left: 25px; }
        .footer--form .form--block .block--inputs {
          flex-direction: column; }
          .footer--form .form--block .block--inputs .input--element {
            width: 100%;
            height: 50px; }
          .footer--form .form--block .block--inputs .input--submit {
            padding: 10px; } }
    @media (max-width: 1269px) {
      .footer--form .form--block {
        width: 100%;
        margin-left: 0;
        align-items: center; }
        .footer--form .form--block .block--title {
          font-weight: 400;
          line-height: 120%;
          text-align: center; }
        .footer--form .form--block .block--description {
          font-weight: 400;
          line-height: 120%;
          text-align: center; }
        .footer--form .form--block .block--agree {
          text-align: center; }
      .footer--form .form--background {
        left: -100px;
        right: 10px;
        top: -206px;
        bottom: 0; }
        .footer--form .form--background .background--image {
          top: 0;
          left: 0;
          right: 0;
          height: 260px;
          width: 100%;
          object-fit: contain; } }
  .footer--content {
    border: solid 1px #FFFFFF;
    border-left: 0;
    border-right: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 116px;
    margin-bottom: 25px;
    display: flex; }
    .footer--content .content--logo {
      width: 165px;
      height: 65px;
      margin-right: 80px; }
      @media (max-width: 1920px) {
        .footer--content .content--logo {
          margin-right: calc(0.02174 * 100vw + 38.26087px); } }
      .footer--content .content--logo .logo--image {
        width: 100%;
        max-width: 200px;
        height: 100%;
        object-fit: contain; }
    .footer--content .content--links {
      flex-grow: 1; }
      .footer--content .content--links .links--table {
        display: flex;
        gap: 10px;
        justify-content: space-between; }
        .footer--content .content--links .links--table .table--column {
          display: flex;
          flex-direction: column;
          gap: 20px; }
          .footer--content .content--links .links--table .table--column .table--row {
            font-weight: 400;
            font-size: 21px;
            line-height: 125%;
            color: #FFFFFF; }
            .footer--content .content--links .links--table .table--column .table--row:hover {
              text-decoration: underline; }
          @media (max-width: 1979px) {
            .footer--content .content--links .links--table .table--column {
              gap: calc(0.00625 * 100vw + 8px); }
              .footer--content .content--links .links--table .table--column .table--row {
                font-size: calc(0.00313 * 100vw + 15px); } }
    .footer--content .content--information {
      margin-left: 112px;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      color: rgba(255, 255, 255, 0.75); }
      .footer--content .content--information .information--list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px; }
        .footer--content .content--information .information--list .list--item * {
          color: rgba(255, 255, 255, 0.75); }
        .footer--content .content--information .information--list .list--item.item--email {
          text-decoration: underline; }
    @media (max-width: 1550px) {
      .footer--content .content--links .links--table {
        gap: 40px; }
      .footer--content .content--information {
        margin-left: 30px; } }
    @media (max-width: 1279px) {
      .footer--content {
        margin-top: 60px; }
        .footer--content .content--links .links--table {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px; }
          .footer--content .content--links .links--table .table--column {
            gap: 20px; }
            .footer--content .content--links .links--table .table--column .table--row {
              height: 38px; } }
    @media (max-width: 1050px) {
      .footer--content {
        flex-direction: column; }
        .footer--content .content--logo {
          margin-bottom: 40px;
          margin-right: 0;
          display: flex;
          width: 100%; }
        .footer--content .content--links {
          display: none; }
        .footer--content .content--information {
          margin-left: 0; }
          .footer--content .content--information .information--list {
            gap: 20px; }
            .footer--content .content--information .information--list .list--item {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px; } }
  .footer--bottom {
    display: flex;
    justify-content: space-between; }
    .footer--bottom .bottom--element {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #72B3FF; }
      .footer--bottom .bottom--element * {
        color: #72B3FF !important; }
      .footer--bottom .bottom--element.element--placestart {
        display: flex;
        align-items: center;
        gap: 9px;
        text-decoration: none; }
        .footer--bottom .bottom--element.element--placestart .placestart--icon {
          width: 144px;
          height: 28px;
          fill: #72B3FF; }
    @media (max-width: 1050px) {
      .footer--bottom {
        flex-direction: column;
        gap: 14px; }
        .footer--bottom .bottom--element {
          color: #72B3FF;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px; } }

.search-line {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #FFD249;
  width: 100%;
  height: 45px;
  position: relative; }
  .search-line.error {
    border-color: #ff2828; }
  .search-line input {
    flex: 1 0 auto;
    border: none;
    height: 100%;
    padding: 0 15px; }
  .search-line button {
    border: none;
    width: 45px;
    height: 45px;
    position: relative;
    right: -1px; }
    .search-line button svg {
      width: 20px;
      height: 20px;
      stroke: #282828;
      fill: transparent; }
  .search-line__result {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    background-color: #fff;
    padding: 30px 20px; }
    .search-line__result.opened {
      display: block; }
    .search-line__result:empty {
      display: none !important; }
  .search-line__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #282828;
    margin: 0 0 10px; }
  .search-line__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }
    .search-line__item span {
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }
    .search-line__item svg {
      height: 12px;
      width: 8px;
      fill: #999999; }
    .search-line__item:hover span {
      color: #E5A701; }
  .search-line__extra {
    border-top: 1px solid #D0D0D0;
    padding: 15px 0 0;
    margin: 30px 0 0; }

.login-block {
  max-width: 500px; }

.login-info {
  margin: 0 0 20px; }
  .login-info__text {
    font-size: 15px;
    line-height: 24px;
    color: #282828; }

.login-form {
  position: relative; }

.login-forgot {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D0D0D0;
  margin: 15px 0 0;
  padding: 15px 0 0; }
  .login-forgot > a, .login-forgot > span {
    font-size: 15px;
    line-height: 18px;
    color: #999999; }
    .login-forgot > a > a, .login-forgot > span > a {
      color: #E5A701;
      text-decoration: underline; }
  @media (max-width: 419px) {
    .login-forgot {
      flex-direction: column-reverse;
      align-items: center; }
      .login-forgot > a {
        margin: 10px 0 0; } }
